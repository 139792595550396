@import "vendor.scss";
@import "input-range.scss";
@import "fancy-checkbox.scss";

// variables
$font-family-sans-serif: 'Open Sans', sans-serif;

$font-family-base: $font-family-sans-serif !default;
$font-family-title: $font-family-sans-serif !default;

$gray: #666666;
$gray2: #999999;
$blue: #6d9eeb;

//not working?!
// $primary: #81acf2 !important;
$primary: #3a638a !important;

//workaround..
.btn-primary {
  background-color: $primary;
  border-color: $primary;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, div, span {
  font-family: $font-family-sans-serif !important;
  font-weight: 300;
}

h2 {
  font-size: 36px;
}

h5 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 2rem;
}

$background2: #fafafa;

p {
  text-align: justify;
  font-size: 16px;
  color: #999;
  line-height: 1.8;
}

form {
  ::placeholder {
    opacity: 0.3 !important;
  }
}

@media (max-width: 991.98px) {
  .text-justify{
    padding-left: 20px;
    padding-right: 20px;
  }
}

.gray-text {
  color: #999;
}

.hidden {
  display: none;
}

.anim {
  transition: 250ms opacity;
}
.anim-shown {
  opacity: 1;
}
.anim-hidden {
  opacity: 0;
}

.navbar {
  &.pre-scroll {
    // transform: top(-100px);
    top: -100px;
  }

  &.scrolled {
    position: fixed;
    top: 0px;
    // transform: top(0px);
    transition: all 250ms;
    background-color: #e7e7e7;

    .nav-link {
      color: $gray2 !important;
    }

    .logo-text {
      color: $gray2;
    }
  }

  &.navbar-light {
    background: #f5f6f7;
    
  }

  .logo-text {
    color: white;
  }

  .nav-link {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: $font-family-sans-serif;
    color: white !important;
    padding-right: 0px;
    padding-left: 34px;

    &:hover {
      opacity: 0.75;
    }
  }

  .navbar-toggler {
    //color: #6666;
    padding-right: 0px;
  }
}



.navbar-open .navbar-mobile {
  opacity: 0.85;
  transition: right 300ms;
  right: 0 !important;
  left: unset !important;

  .nav-link {
    color: $gray !important;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-mobile {
    transition: right 300ms;
    right: -300px;
    left: unset;
  }
}

.inline-logo {
  cursor: default;

  img {
    position: relative;
    top: 2px;
    display: inline-block;
  }
  .logo-text {
    position: relative;
    top: -3px;
    font-size: 24px;
    font-weight: 300;
    padding-left: 10px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    b {
      font-weight: bolder;
    }
  }
}

.footer {
  .inline-logo {
    img {
      position: relative;
      top: -3px;
    }
    .logo-text {
      position: relative;
      top: 2px;
      color: $gray2;
    }
  }
  .nav-link {
    font-weight: 300;
    color: $gray2;
  }
}

.title-section {
  
  margin-bottom: 60px;
 
  @media (max-width: 991.98px) {
    margin-bottom: 30px;
  }
  
  color: #999;
  
  
}

.gradient {
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(134, 134, 134, 0.603) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(134, 134, 134, 0.65) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(54, 54, 54, 0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  height:280px; width: 100%; position: absolute; bottom:0px;
}


.section {
  
  padding-top: 6rem;

  @media (max-width: 991.98px) {
    padding-top: 3rem;
  }
  
  &-home {
    top: -50px;
    background-image: url('../img/vrguycropoptimised.jpg');
    padding-bottom: 0rem;

    .title-section {
      padding-top: 495px;
      color: white;
    }
  }
  &-b {
    background: $background2;
  }
}

@media (min-width: 991.98px) {
  .card {
    padding: 1.25rem 0;
  }
}

#section-services {
  .card {
    

    h5 {
      text-align: center;
      //@at-rootcolor: $gray2;
      color: $primary;
    }

    .info-text, .learn-more {
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: justify;
      
      @media (min-width: 991.98px) {
        padding: 10px;
      }
      
    }

    


    .learn-more{
      color: $primary;

    }

    .image {
      margin-top : 0px !important;

      img {
        width: 180px;
      }
    }
  }
}

#section-how {
  .card {
    background-color: #fafafa;
    h5 {
      text-align: center;
      //@at-rootcolor: $gray2;
      color: $primary;
    }

    .info-text, .learn-more {
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: justify;
      
      @media (min-width: 991.98px) {
        padding: 10px;
      }
      
    }

    


    .learn-more{
      color: $primary;

    }

    .image {
      margin-top : 0px !important;

      img {
        width: 180px;
      }
    }
  }
}

#section-technology {
  .diagram-container {
    padding: 40px;
  }
}

#section-team {
  h5 {
    margin-bottom: 0.5rem;
  }

  small {
    font-size: 17px;
    color: #999;
  }
}

#section-demos {
  .card {
    //margin-bottom: 10px;
    font-size: 15px;
    color: $gray2;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    border-color: $gray;
    border-top: 1px !important;
    border-left: 1px !important;
    border-right: 1px !important;

    .card-body{
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 0px;
      padding-right: 0px;

    }

    .card-alt-text {
      font-size: 15px;
      font-family: "Open Sans";
      font-weight: 500;
      color: white;
      padding-left: 10px;
      padding-top: 150px;

    }

    .container{
      padding: 0px;
    }

    .text {
      font-weight: 700;
      color: $gray2;
      position: relative;
      top: 2px;
    }

    .progress {
      width: 100%;
      position: relative;
      top: -3px;
    }

    .vrx-container {
      text-align: right;
      //padding-left: 10px;
      color: $primary;
      position: relative;
      top: -2px;

      span {
        // transform: scale(1.25);
        display: inline-block;
        //padding-right: 17.5px;
        //@media (max-width: 480px){padding-right: 5px;}
        
      }
      
      .switch {
        position: relative;
        top: 5px;
        margin-left: 10px;
        transition: 0.3s all;

        &:hover {
          opacity: 0.85;
          transform: scale(1.05);
        }
      }
    }
    .player-container {
      color: $primary;
      position: relative;
      top: 2px;

      .play, .pause {
        transform: scale(1.75);
        transition: 0.3s all;
        cursor: pointer;

        &:hover {
          opacity: 0.85;
          transform: scale(2);
        }
      }
    }
  }

  .title-section-video {
    margin-top: 6rem;
  }
}

.video-thumbnail {
  //position: relative;
  //display: inline-block;
  cursor: pointer;
  //margin: 40px;

  &:before {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\25b6";
    font-family: FontAwesome;
    font-size: 40px;
    color: #fff;
    opacity: .8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  &:hover:before {
    -webkit-transform: translate(-48%, -48%);
    transform: translate(-48%, -48%);
    
    transition: 0.3s all;

    color: #eee;
  }
}

#section-faq {
  .accordion {
    .card {
      padding: 0;
    }
  }
}

.circleBase {
  border-radius: 50%;
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: $primary;
  text-align: center;
  color: white;
  font-size: 25px;
  font-family: "Open Sans";
  font-weight: 1000;
  top: 1px;
  
  //margin-bottom: 50px;
  
 
  
}



//#section-contact {}
